<template>
  <div style="background-color: #fafafa">
    <Myhead :nav="4" :nav2="2"></Myhead>
    <div class="header">
      <div class="header-text">
        <div class="header-title">{{Language.knowledge_list.company_news}}</div>
        <div class="btn-contactUs">{{Language.knowledge_list.contactUs}}</div>
      </div>
    </div>
    <div class="router">
      <div class="routerbox" style="width: 1200px; margin: 0 auto;">
        <router-link class="routerLink" to="/">{{Language.knowledge_list.home_page}}</router-link> >
        <span class="routerLink" @click="reload">{{Language.knowledge_list.resource_centre}}</span> >
        <span @click="reload" style="color: var(--theme-color1);cursor: pointer">{{Language.knowledge_list.company_news}}</span>
      </div>
    </div>
    <div class="container">
      <div class="title">{{Language.knowledge_list.Classified_reading}}</div>
      <div class="cate">
        <div class="cate-item" @click="category({id:''})" v-if="Language_type == 1"> 全部</div>
        <div class="cate-item" @click="category({id:''})" v-if="Language_type != 1"> All</div>
        <div class="cate-item" v-for="(item, index) in cateList" :key="index" @click="category(item)">
          <span v-if="Language_type == 1">{{ item.category_name }}</span>
          <span v-if="Language_type != 1">{{ item.category_name_en }}</span>
        </div>
      </div>
      <div class="list">
        <div class="list-item" v-for="(item, index) in knowledgeList" :key="index" @click="toknowledgeDetail(item)">
          <div class="list-img">
            <img class="imgStyle" :src="$imgurl + '/' + item.cover_image" :alt="item.cover_image_alt"/>
          </div>
          <div class="list-content">
            <div class="content-title">{{ Language_type == 1 ? item.title : item.title_en }}</div>
            <div class="divider"></div>
            <div class="content-date">{{ item.upload_date }}</div>
            <div class="content-abstract">{{ Language_type == 1 ? item.brief_content : item.brief_content_en }}</div>
          </div>
        </div>
      </div>
      <div class="pagination" v-if="pagination.total > 0">
        <el-pagination
            class="p"
            background
            :page-size="pagination.pageSize"
            :total="pagination.total"
            layout="prev, pager, next"
            :current-page="pagination.pageNo"
            @current-change="currentChange"
        />
      </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";
export default {
  inject:["reload",'L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components:{
    Myhead,
    Myfoot
  },
  data() {
    return {
      cateList: [],
      knowledgeList: [],
      pagination: {pageNo: 1, pageSize: 20, total: 100},
      category_id:"",
      Language_type:"",
    }
  },
  activated() {

  },
  mounted() {
    this.Language_type = localStorage.getItem("Language_type")
    this.getknowledge_category()
    this.getknowledge()
  },
  methods: {
    getknowledge_category() {
      this.$get("/m/indexset/news_category").then(res => {
        console.log(res)
        if(res.data){
          this.cateList = res.data.data
        }
      })
    },
    getknowledge() {
      var data = {
        ob:'order by upload_date desc'
      }
      if(this.category_id){
        data.category_id = this.category_id
      }
      this.$get("/m/indexset/news", data).then(res => {
        console.log(res)
        if(res.data){
          this.knowledgeList = res.data.data
          this.pagination={
            pageNo: res.data.pageNo, pageSize: res.data.pageSize, total: res.data.totalCount
          }
        }
      })
    },
    currentChange(value) {
      console.log(value)
    },
    toknowledgeDetail(item){
      console.log(item)
      this.$router.push({
        path:"/resources/news/detail/" + item.url_key,
      })
    },
    category(item){
      console.log(item)
      this.category_id = item.id
      this.getknowledge()
    },
  }
}
</script>
<style lang="scss" scoped>
.header {
  margin-top: -70.5px;
  width: 100%;
  height: 360px;
  color: #FFFFFF;
  background-image: url("../assets/img/knowledgeList/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .header-text {
    max-width: 1200px;
    margin: auto;
    padding-top: 150px;

    .header-title {
      font-size: 50px;
      margin-bottom: 24px;
    }
  }
}

.router {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  font-size: 14px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 0 120px;

  .title {
    font-size: 42px;
    text-align: center;
  }

  .cate {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;

    .cate-item {
      width: 175px;
      height: 40px;
      line-height: 40px;
      background-color: #27BA6919;
      color: var(--theme-color1);
      border-radius: 100px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  .list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 80px;

    .list-item {
      width: 380px;
      background-color: #ffffff;
      border-radius: 24px;
      cursor: pointer;
      .list-img {
        //width: 380px;
        height: 200px;
        border-radius: 24px 24px 0 0;
        img{
          object-fit: cover;
        }
      }

      .list-content {
        padding: 16px 24px 24px;

        .content-title {
          font-size: 23px;
          font-weight: bold;
          color: var(--title-color);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          word-break: break-all;
          -webkit-box-orient: vertical;
          height: 100px;
        }

        .divider {
          border-top: 1px solid var(--theme-color1);
          margin: 16px 0;
        }

        .content-date {
          font-size: 14px;
          color: var(--theme-color1);
          margin-bottom: 8px;
        }

        .content-abstract {
          font-size: 14px;
          color: var(--text-color);
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          word-break: break-all;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .pagination {
    text-align: center;
    margin-top: 80px;

    .p {
      ::v-deep .btn-prev {
        background-color: #ffffff;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }

      ::v-deep .btn-prev .el-icon {
        font-size: 16px;
      }

      ::v-deep button i {
        color: var(--theme-color1);
      }

      ::v-deep button[disabled='disabled'] i {
        color: #cccccc;
      }

      ::v-deep .el-pager li {
        background-color: #ffffff;
        margin: 0 28px;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }

      ::v-deep .el-pager .number {
        color: #cccccc;
        font-size: 16px;
      }

      ::v-deep .el-pager .active {
        color: var(--theme-color1);
      }

      ::v-deep .btn-next {
        background-color: #ffffff;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }

      ::v-deep .btn-next .el-icon {
        font-size: 16px;
      }
    }
  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .header{
    .header-text{
      width: 100%;
      padding: 130px 20px 0;
    }
  }
  .router{
    .routerbox{
      padding: 0px 20px;
    }
  }
  .container{
    .cate{
      padding: 0px 20px;
    }
  }
  .list{
    padding: 0px 20px;
  }
}

@media (min-width: 835px) and (max-width: 1200px){
  .container{
    .list{
      .list-item{
        width: 49%;
        .list-img{
          height: 300px;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 835px){
  .container{
    .list{
      padding: 0;
      .list-item{
        width: 100%;
        .list-img{
          height: 300px;
        }
      }
    }
  }
}
</style>
